const catalogue = [
  {
    name: "Hardware",
    category: [
      { 
        name: "Server",
        tags: ["Enterprise Server", "Small Medium Business Server"],
        list: [
          { name: "HPE", tag: "Enterprise Server" },
          { name: "LENOVO", tag: "Enterprise Server" },
          { name: "DELL", tag: "Enterprise Server" },
          { name: "Inspur", tag: "Enterprise Server" },
          { name: "Zoan", tag: "Enterprise Server" },
          { name: "DELL EMC", tag: "Enterprise Server" },
          { name: "Sangfor", tag: "Enterprise Server" },
          { name: "FUJITSU", tag: "Enterprise Server" },
          { name: "Nutanix", tag: "Enterprise Server" },
          { name: "DELL", tag: "Small Medium Business Server" },
          { name: "HPE", tag: "Small Medium Business Server" },
          { name: "LENOVO", tag: "Small Medium Business Server" },
          { name: "ACER", tag: "Small Medium Business Server" },
          { name: "ASUS", tag: "Small Medium Business Server" },
          { name: "Zoan", tag: "Small Medium Business Server" }
        ]
      },
      { 
        name: "Storage",
        tags: ["Network Area Storage", "Storage Area Storage", "Tape Disc Backup", "Direct Attach Storage", "Storage Option"],
        list: [
          { name: "NAS Rackmount", tag: "Network Area Storage" },
          { name: "NAS Tower", tag: "Network Area Storage" },
          { name: "SAN Rackmount", tag: "Storage Area Storage" },
          { name: "LTO Backup", tag: "Tape Disc Backup" },
          { name: "DAS Rackmount", tag: "Direct Attach Storage" },
          { name: "DAS Tower", tag: "Direct Attach Storage" },
          { name: "Cartridge", tag: "Storage Option" }
        ],
        vendor: [
          { name: "QNAP" },
          { name: "SYNOLOGY" },
          { name: "HPE" },
          { name: "ASUSTOR" },
          { name: "DELL EMC" },
          { name: "DELL" },
          { name: "LENOVO" },
          { name: "Overland Tandberg" },
          { name: "IBM" },
          { name: "TANDBERG" },
          { name: "FUJIFILM" },
          { name: "WD" },
          { name: "HITACHI" },
          { name: "HP" },
          { name: "DROBO" },
          { name: "HUAWEI" },
          { name: "QSAN" },
        ]
      },
      { 
        name: "Power/UPS",
        list: [
          { name: "UPS Tower Expandable" },
          { name: "UPS Tower Non Expandable" },
          { name: "UPS Rackmount Expandable" },
          { name: "UPS Desktop / Home / Consumer" },
          { name: "UPS Rackmount Non Expandable" },
          { name: "UPS Solar Energy" },
        ],
        vendor: [
          { name: "APC" },
          { name: "PROLINK" },
          { name: "YOSHIGA" },
          { name: "CYBERPOWER" },
          { name: "POWER" },
          { name: "EATON" },
          { name: "SOCOMEC" },
          { name: "GTEC" },
          { name: "EMMERICH" },
          { name: "LEXOS" },
          { name: "ICA" },
          { name: "Vertiv" },
          { name: "PowerWave" },
          { name: "KSTAR" },
          { name: "SENDON" },
          { name: "Fuji Electric" },
          { name: "Borru" },
          { name: "MAKELSAM" },
          { name: "VEKTOR AVLEREX" },
          { name: "SIERRA" },
          { name: "ABB" },
          { name: "LAPLACE" },
          { name: "UNIPOWER" },
          { name: "LEGRAND" },
          { name: "Edcon" },
          { name: "MICROPACK" },
          { name: "WEARNER" },
          { name: "AcerAltos" },
          { name: "FSP" },
          { name: "TRON" },
          { name: "ARAKAWA" },
          { name: "EPI" },
          { name: "HELIOR" },
          { name: "IBOS" },
          { name: "Salicru" },
          { name: "Scala" },
        ]
      },
      { 
        name: "Rack System",
        list: [
          { name: "Rack System Closed" },
          { name: "Rack System Wallmount" },
          { name: "Rack System Open" }
        ],
        vendor: [
          { name: "ABBA" },
          { name: "INDORACK" },
          { name: "INNOVATION RACK" },
          { name: "APC" },
          { name: "ASTERIX" },
          { name: "DtC NETCONNECT" },
          { name: "Rosenberger" },
          { name: "Vertiv" },
          { name: "AMP" },
          { name: "B-SAVE" },
          { name: "DTC" },
          { name: "HaganeRack" },
          { name: "INNOVATION" },
          { name: "LEGRAND" },
          { name: "LENOVO" },
          { name: "NIRAX" },
        ]
      },
      { 
        name: "Networking",
        tags: ["Switch", "Router", "Access Point", "Load Balancer"],
        list: [
          { name: "Managed Switch", tag: "Switch" },
          { name: "Unmanaged Switch", tag: "Switch" },
          { name: "Switch Module", tag: "Switch" },
          { name: "Switch Poe Splitter", tag: "Switch" },
          { name: "Switch Sfp Transreciever", tag: "Switch" },
          { name: "Switch Power Supply", tag: "Switch" },
          { name: "Switch Converter", tag: "Switch" },
          { name: "Switch Adapter", tag: "Switch" },
          { name: "Router Consumer Wired", tag: "Router" },
          { name: "Router Enterprise", tag: "Router" },
          { name: "Router Extender", tag: "Router" },
          { name: "Powerline Adapter", tag: "Router" },
          { name: "Router Consumer Wireless", tag: "Router" },
          { name: "Router Accessory", tag: "Router" },
          { name: "Router Module", tag: "Router" },
          { name: "HPE Networking", tag: "Access Point" },
          { name: "UBIQUITI", tag: "Access Point" },
          { name: "TP-LINK", tag: "Access Point" },
          { name: "RUIJIE", tag: "Access Point" },
          { name: "CISCO", tag: "Access Point" },
          { name: "MIKROTIK", tag: "Access Point" },
          { name: "NETGEAR", tag: "Access Point" },
          { name: "RUCKUS", tag: "Access Point" },
          { name: "D-LINK", tag: "Access Point" },
          { name: "HPE", tag: "Access Point" },
          { name: "ARUBA", tag: "Access Point" },
          { name: "B-SAVE", tag: "Access Point" },
          { name: "PROLINK", tag: "Access Point" },
          { name: "TOTOLINK", tag: "Access Point" },
          { name: "TRENDNET", tag: "Access Point" },
          { name: "KEMP", tag: "Load Balancer" },
          { name: "CITRIX", tag: "Load Balancer" },
        ],
        tags_vendor: [
          { 
            name: "Switch",
            list: [
              { name: "TP-LINK" },
              { name: "D-LINK" },
              { name: "NETGEAR" },
              { name: "HPE Networking" },
              { name: "TRENDNET" },
              { name: "DELL" },
              { name: "JUNIPER" },
              { name: "HP" },
              { name: "HPE" },
              { name: "HIKVISION" },
              { name: "LINKSYS" },
              { name: "DAHUA" },
              { name: "Ruijie" },
              { name: "H3C" },
              { name: "Kinan" },
              { name: "LENOVO" },
              { name: "Mikrobits" },
              { name: "NETLINK" },
              { name: "Sundray" },
              { name: "ALCATEL" },
              { name: "ASUS" },
              { name: "Edgecore" },
              { name: "Extreme Networks" },
              { name: "FLEXTREME" },
              { name: "Finisar" },
              { name: "HITACHI" },
              { name: "IBM" },
              { name: "ICOM" },
              { name: "ONV" },
              { name: "RAKWireless" },
              { name: "ROUTERBOARD" },
              { name: "SPC" },
              { name: "VIVOTEK" },
            ]
          },
          { 
            name: "Router",
            list: [
              { name: "TP-LINK" },
              { name: "MIKROTIK" },
              { name: "NETGEAR" },
              { name: "ASUS" },
              { name: "LINKSYS" },
              { name: "TENDA" },
              { name: "PROLINK" },
              { name: "D-LINK" },
              { name: "Mercusys" },
              { name: "TOTOLINK" },
              { name: "UBIQUITI" },
              { name: "JUNIPER" },
              { name: "XIAOMI" },
              { name: "ADVAN" },
              { name: "HUAWEI" },
              { name: "TRENDNET" },
              { name: "CISCO" },
              { name: "HPE" },
              { name: "LIBERA" },
              { name: "AIO" },
              { name: "IP-COM" },
              { name: "Maipu" },
              { name: "Mikrobits" },
              { name: "OWC" },
              { name: "Ruijie" },
              { name: "SPC" },
              { name: "TELTONIKA" },
              { name: "WISNETWORKS" },
            ]
          }
        ]
      },
      { 
        name: "Network Security",
        tags: ["Firewall"],
        list: [
          { name: "FORTINET", tag: "Firewall" },
          { name: "CHECKPOINT", tag: "Firewall" },
          { name: "Sonicwall", tag: "Firewall" },
          { name: "JUNIPER", tag: "Firewall" },
          { name: "Versa Networks", tag: "Firewall" },
          { name: "F5", tag: "Firewall" },
          { name: "CISCO", tag: "Firewall" },
          { name: "Sangfor", tag: "Firewall" },
          { name: "PALO ALTO", tag: "Firewall" },
          { name: "Gigamon", tag: "Firewall" },
          { name: "NEETGEAR", tag: "Firewall" },
          { name: "Ruijie", tag: "Firewall" },
        ]
      },
      { 
        name: "Teleconference Video & Audio",
        list: [
          { name: "POLYCOM" },
          { name: "LOGITECH" },
          { name: "TOA" },
          { name: "AVER" },
          { name: "Poly" },
          { name: "BOSCH" },
          { name: "YEALINK" },
          { name: "AKG" },
          { name: "JABRA" },
          { name: "TELEVIC" },
          { name: "ANYSONG" },
          { name: "PRIMATECH" },
          { name: "VANNOE" },
          { name: "AMX" },
          { name: "Digimax" },
          { name: "BENQ" },
          { name: "SPC" },
          { name: "Tenveo" },
          { name: "AUDIO-TECHNICA" },
          { name: "Arec" },
          { name: "Ashley" },
          { name: "BOYU" },
          { name: "ClearOne" },
          { name: "Edavos" },
          { name: "JETE" },
          { name: "KREZT" },
          { name: "Kandao" },
          { name: "OBSBOT" },
          { name: "SHURE" },
          { name: "iQShare" },
        ]
      },
      { 
        name: "Workststation Desktop",
        list: [
          { name: "DELL" },
          { name: "LENOVO" },
          { name: "HP" }
        ]
      },
      { 
        name: "Laptop/Netbook",
        tags: ["Business Notebook", "Consumer Notebook", "Gaming Notebook"],
        list: [
          { name: "LENOVO", tag: "Business Notebook" },
          { name: "DELL", tag: "Business Notebook" },
          { name: "ASUS", tag: "Business Notebook" },
          { name: "HP", tag: "Business Notebook" },
          { name: "MSI", tag: "Business Notebook" },
          { name: "Dynabook", tag: "Business Notebook" },
          { name: "APPLE", tag: "Business Notebook" },
          { name: "AXIOO", tag: "Business Notebook" },
          { name: "ACER", tag: "Business Notebook" },
          { name: "ASUS", tag: "Consumer Notebook" },
          { name: "LENOVO", tag: "Consumer Notebook" },
          { name: "ACER", tag: "Consumer Notebook" },
          { name: "HP", tag: "Consumer Notebook" },
          { name: "APPLE", tag: "Consumer Notebook" },
          { name: "DELL", tag: "Consumer Notebook" },
          { name: "ADVAN", tag: "Consumer Notebook" },
          { name: "AXIOO", tag: "Consumer Notebook" },
          { name: "HUAWEI", tag: "Consumer Notebook" },
          { name: "Dynabook", tag: "Consumer Notebook" },
          { name: "MSI", tag: "Consumer Notebook" },
          { name: "SAMSUNG", tag: "Consumer Notebook" },
          { name: "XIAOMI", tag: "Consumer Notebook" },
          { name: "MSI", tag: "Gaming Notebook" },
          { name: "ASUS", tag: "Gaming Notebook" },
          { name: "LENOVO", tag: "Gaming Notebook" },
          { name: "ACER", tag: "Gaming Notebook" },
          { name: "HP", tag: "Gaming Notebook" },
          { name: "DELL", tag: "Gaming Notebook" }
        ]
      },
      { 
        name: "Printer & Scanner",
        tags: ["Printer Bisnis", "Scanner Business", "Printer Home", "Scanner Home"],
        list: [
          { name: "EPSON", tag: "Printer Bisnis" },
          { name: "BROTHER", tag: "Printer Bisnis" },
          { name: "HP", tag: "Printer Bisnis" },
          { name: "CANON", tag: "Printer Bisnis" },
          { name: "BIXOLON", tag: "Printer Bisnis" },
          { name: "GOWELL", tag: "Printer Bisnis" },
          { name: "Sano", tag: "Printer Bisnis" },
          { name: "TSC", tag: "Printer Bisnis" },
          { name: "BLUEPRINT", tag: "Printer Bisnis" },
          { name: "ZEBRA", tag: "Printer Bisnis" },
          { name: "HID", tag: "Printer Bisnis" },
          { name: "PRINTRONIX", tag: "Printer Bisnis" },
          { name: "Creality", tag: "Printer Bisnis" },
          { name: "FARGO", tag: "Printer Bisnis" },
          { name: "FUJIFILM", tag: "Printer Bisnis" },
          { name: "JANZ", tag: "Printer Bisnis" },
          { name: "TALLY DASCOM", tag: "Printer Bisnis" },
          { name: "ENTRUST", tag: "Printer Bisnis" },
          { name: "EVOLIS", tag: "Printer Bisnis" },
          { name: "Kassen", tag: "Printer Bisnis" },
          { name: "PANTUM", tag: "Printer Bisnis" },
          { name: "Star Micronics", tag: "Printer Bisnis" },
          { name: "CASIO", tag: "Printer Bisnis" },
          { name: "Cashcow", tag: "Printer Bisnis" },
          { name: "FUJI XEROX", tag: "Printer Bisnis" },
          { name: "HPRT", tag: "Printer Bisnis" },
          { name: "MATRIX POINT", tag: "Printer Bisnis" },
          { name: "POINTMAN", tag: "Printer Bisnis" },
          { name: "CODESOFT", tag: "Printer Bisnis" },
          { name: "FUJITSU", tag: "Printer Bisnis" },
          { name: "MAGICARD", tag: "Printer Bisnis" },
          { name: "OKI", tag: "Printer Bisnis" },
          { name: "Tiertime", tag: "Printer Bisnis" },
          { name: "Zenpert", tag: "Printer Bisnis" },
          { name: "ARGOX", tag: "Printer Bisnis" },
          { name: "COMPUPRINT", tag: "Printer Bisnis" },
          { name: "GOLDWIN", tag: "Printer Bisnis" },
          { name: "HONEYWELL", tag: "Printer Bisnis" },
          { name: "JOYKO", tag: "Printer Bisnis" },
          { name: "KOZURE", tag: "Printer Bisnis" },
          { name: "KYOCERA", tag: "Printer Bisnis" },
          { name: "MATRIX", tag: "Printer Bisnis" },
          { name: "PRUSA", tag: "Printer Bisnis" },
          { name: "SEIKO", tag: "Printer Bisnis" },
          { name: "SMART", tag: "Printer Bisnis" },
          { name: "Seaory", tag: "Printer Bisnis" },
          { name: "ZSA", tag: "Printer Bisnis" },
          { name: "CONTEX", tag: "Scanner Business" },
          { name: "KODAK", tag: "Scanner Business" },
          { name: "AVISION", tag: "Scanner Business" },
          { name: "EPSON", tag: "Scanner Business" },
          { name: "RICOH", tag: "Scanner Business" },
          { name: "ZEBRA", tag: "Scanner Business" },
          { name: "BROTHER", tag: "Scanner Business" },
          { name: "MICROTEK", tag: "Scanner Business" },
          { name: "PANASONIC", tag: "Scanner Business" },
          { name: "DATALOGIC", tag: "Scanner Business" },
          { name: "HP", tag: "Scanner Business" },
          { name: "NEWLAND", tag: "Scanner Business" },
          { name: "Sano", tag: "Scanner Business" },
          { name: "CANON", tag: "Scanner Business" },
          { name: "Viisan", tag: "Scanner Business" },
          { name: "GOWELL", tag: "Scanner Business" },
          { name: "PLUSTEK", tag: "Scanner Business" },
          { name: "SCANLOGIC", tag: "Scanner Business" },
          { name: "SOLUTION", tag: "Scanner Business" },
          { name: "EZOne", tag: "Scanner Business" },
          { name: "Alaris", tag: "Scanner Business" },
          { name: "CODE", tag: "Scanner Business" },
          { name: "CZUR", tag: "Scanner Business" },
          { name: "HONEYWELL", tag: "Scanner Business" },
          { name: "IMIN", tag: "Scanner Business" },
          { name: "Kassen", tag: "Scanner Business" },
          { name: "MAXTOR", tag: "Scanner Business" },
          { name: "Micrographics Data", tag: "Scanner Business" },
          { name: "Smartlogic", tag: "Scanner Business" },
          { name: "Star Micronics", tag: "Scanner Business" },
          { name: "Startek", tag: "Scanner Business" },
          { name: "VIEWSONIC", tag: "Scanner Business" },
          { name: "HP", tag: "Printer Home" },
          { name: "CANON", tag: "Printer Home" },
          { name: "EPSON", tag: "Printer Home" },
          { name: "PANTUM", tag: "Printer Home" },
          { name: "BROTHER", tag: "Printer Home" },
          { name: "Paket", tag: "Printer Home" },
          { name: "KYOCERA", tag: "Printer Home" },
          { name: "AVISION", tag: "Scanner Home" },
          { name: "EPSON", tag: "Scanner Home" },
          { name: "CANON", tag: "Scanner Home" },
          { name: "BROTHER", tag: "Scanner Home" },
          { name: "RICOH", tag: "Scanner Home" },
          { name: "HP", tag: "Scanner Home" },
        ]
      },
      { 
        name: "Display Solution",
        list: [
          { name: "Digital Signage" },
          { name: "Video Wall" },
          { name: "Kiosk" },
          { name: "Aksesoris" },
          { name: "Videotron" },
        ],
        vendor: [
          { name: "SAMSUNG" },
          { name: "LG" },
          { name: "DIGISIGN" },
          { name: "PHILIPS" },
          { name: "HIKVISION" },
          { name: "VIEWSONIC" },
          { name: "Uapic" },
          { name: "BENQ" },
          { name: "PANASONIC" },
          { name: "Onesia" },
          { name: "AOC" },
          { name: "GOODVIEW" },
          { name: "INSIGHT" },
          { name: "Vestouch" },
          { name: "ACER" },
          { name: "Ice Board" },
          { name: "Ligthking" },
          { name: "ProLight" },
          { name: "ANYLINX" },
          { name: "B-SAVE" },
          { name: "HONORVISION" },
          { name: "BOE" },
          { name: "BTECH" },
          { name: "MAXTOR" },
          { name: "MICROVISION" },
          { name: "ZaagTech" },
          { name: "iSEMC" }
        ]
      },
      { 
        name: "Perangkat Presentasi",
        list: [
          { name: "Projector" },
          { name: "Papan Tulis Interaktif/Copyboard" },
          { name: "Layar Projector/Screen" },
          { name: "Aksesoris Presentasi" },
          { name: "Laser Pointer" },
        ],
        vendor: [
          { name: "Ice Board" },
          { name: "BENQ" },
          { name: "VIEWSONIC" },
          { name: "BRITE" },
          { name: "MICROVISION" },
          { name: "NEC" },
          { name: "PANASONIC" },
          { name: "DRAPER" },
          { name: "SCREENVIEW" },
          { name: "Maxhub" },
          { name: "EPSON" },
          { name: "INFOCUS" },
          { name: "IBRIGHT" },
          { name: "D-LIGHT" },
          { name: "PixelScreen" },
          { name: "PLUS" },
          { name: "PROMETHEAN" },
          { name: "ProLight" },
          { name: "ACER" },
          { name: "GM" },
          { name: "HIKVISION" },
          { name: "Horion" },
          { name: "CASA SCREEN" },
          { name: "Letaec" },
          { name: "LG" },
          { name: "CANON" },
          { name: "SONY" },
          { name: "ATEN" },
          { name: "DATALITE" },
          { name: "INSIGHT" },
          { name: "Inknoe" },
          { name: "MAXELL" },
          { name: "AXIOO" },
          { name: "Chartu" },
          { name: "B-SAVE" },
          { name: "CARL" },
          { name: "EROC" },
          { name: "IQBoard" },
          { name: "LOGITECH" },
          { name: "Onesia" },
          { name: "SAMSUNG" },
          { name: "Xoceco" },
          { name: "AOC" },
          { name: "AVER" },
          { name: "BELYST" },
          { name: "HOVERCAM" },
          { name: "Leseen" },
          { name: "OPTOMA" },
          { name: "EINSTRUCTION" },
          { name: "FUJITSU" },
          { name: "JOYKO" },
          { name: "VANNOE" },
          { name: "Cybernetyx" },
          { name: "HITACHI" },
          { name: "IDEAL" },
          { name: "MAXTOR" },
          { name: "MICROPACK" },
          { name: "MY SCREEN" },
          { name: "PINERI" },
          { name: "RICOH" },
          { name: "TARGUS" },
          { name: "TriColor" },
          { name: "World Screen" },
          { name: "ASUS" },
          { name: "AUKEY" },
          { name: "Ascreen" },
          { name: "BTECH" },
          { name: "CASIO" },
          { name: "Cue" },
          { name: "DIGIBIRD" },
          { name: "GOODVIEW" },
          { name: "HSM" },
          { name: "Indovickers" },
          { name: "Inforce" },
          { name: "Kramer" },
          { name: "M.Eagle" },
          { name: "MAGNIMAGE" },
          { name: "POLYTRON" },
          { name: "SMART Kapp" },
          { name: "SPC" },
          { name: "Sensa" },
          { name: "Vestouch" },
          { name: "WriteBest" },
          { name: "iTBoard" },
        ]
      }
    ]
  },
  {
    name: "Software & Application",
    category: [
      { 
        name: "Software Operating System",
        list: [
          { name: "Software OS & Virtualization Licensing" },
	        { name: "Software Operating System Retail" },
        ],
        vendor: [
          { name: "Retrospect" },
          { name: "VMWARE" },
          { name: "MICROSOFT" },
          { name: "REDHAT" },
          { name: "STORAGECRAFT" },
          { name: "VERITAS" },
          { name: "CITRIX" },
          { name: "Proxmox" },
        ]
      },
      { 
        name: "Software Antivirus & Security",
        list: [
          { name: "Software Security Licensing" },
	        { name: "Software Antivirus Licensing" },
        ],
        vendor: [
          { name: "Retrospect" },
          { name: "BITDEFENDER" },
          { name: "KASPERSKY" },
          { name: "ESET" },
          { name: "MDaemon" },
          { name: "STORAGECRAFT" },
          { name: "FORCEPOINT" },
          { name: "F-Secure" },
          { name: "PANDA" },
          { name: "TRENDMICRO" },
          { name: "Trellix" },
          { name: "MCAFEE" },
          { name: "Avast" },
          { name: "MICROSOFT" },
          { name: "NESSUS" },
          { name: "PortSwigger" },
          { name: "SOPHOS" },
          { name: "SYMANTEC" },
          { name: "ORCA SECURITY" },
          { name: "PROOFPOINT" },
        ]
      },
      { 
        name: "Software Management Licensing",
        list: [
          { name: "Software Messaging Server Licensing" },
          { name: "Software Network Licensing" },
          { name: "Software Remote Access Licensing" },
          { name: "Software Property Management Licensing" },
        ],
        vendor: [
          { name: "Retrospect" },
          { name: "Alibaba Cloud" },
          { name: "Solarwinds" },
          { name: "Versa Networks" },
          { name: "MICROSOFT" },
          { name: "KEMP" },
          { name: "STORAGECRAFT" },
          { name: "CHECKPOINT" },
          { name: "F5" },
          { name: "Lookout" },
          { name: "MANAGEENGINE" },
          { name: "PALO ALTO" },
          { name: "Actifio" },
          { name: "FORCEPOINT" },
          { name: "IBM" },
          { name: "MDaemon" },
          { name: "VERITAS" },
          { name: "CITRIX" },
          { name: "FORTINET" },
          { name: "Radmin" },
          { name: "RemoteUtilities" },
        ]
      },
      { 
        name: "Software Office Productivity Licensing",
        list: [
          { name: "Software Office Application Licensing" },
	        { name: "Software Video Conferencing" },
        ],
        vendor: [
          { name: "MICROSOFT" },
          { name: "GOOGLE" },
          { name: "CISCO" },
          { name: "ZOOM" },
          { name: "MDaemon" },
          { name: "ADOBE" },
          { name: "WPS" },
          { name: "NITRO" },
          { name: "YEALINK" },
          { name: "Cirrust" },
          { name: "Foxit" },
          { name: "iSeller" },
          { name: "NUANCE" },
          { name: "Think Cell" },
          { name: "Asana" },
          { name: "CCleaner" },
          { name: "DocuSign" },
          { name: "Minitab" },
          { name: "POLYCOM" },
          { name: "Retrospect" },
          { name: "SPC" },
          { name: "Smartsheet" }
        ]
      },
      { 
        name: "Software Backup & Utility Licensing",
        list: [
          {name: "Software Backup and Recovery Licensing" },
	        {name: "Software File Compression Licensing" }

        ],
        vendor: [
          { name: "ACRONIS" },
          { name: "Retrospect" },
          { name: "VERITAS" },
          { name: "VEEAM" },
          { name: "MICROSOFT" },
          { name: "TEAMVIEWER" },
          { name: "STORAGECRAFT" },
          { name: "IBM" },
        ]
      },
      { 
        name: "Software Multimedia Licensing",
        list: [
          { name: "Software Photo Editing Licensing" },
          { name: "Software Desktop Publishing Licensing" },
          { name: "Software Video Capture / Editing Licensing" },
          { name: "Software Illustration / Design Licensing" },
          { name: "Software Animation / 3d Licensing" },
          { name: "Software Audio Recording/Editing Licensing" },
        ],
        vendor: [
          { name: "ADOBE" },
          { name: "HITACHI" },
          { name: "Alibaba Cloud" },
          { name: "AUTODESK" },
          { name: "Vegas" },
          { name: "Lumion" },
          { name: "CorelDRAW" },
          { name: "SketchUp" },
          { name: "COREL" },
          { name: "Canva" },
          { name: "Stata" },
          { name: "vMix" },
          { name: "BLACKMAGIC DESIGN" },
          { name: "Freepik" },
          { name: "MICROSOFT" },
          { name: "Midas" },
          { name: "NiceLabel" },
          { name: "Powtoon" },
          { name: "Rhinoceros" }
        ]
      },
      { 
        name: "Software Database & Analysis Licensing",
        list: [
          { name: "Software Network Mapping and Monitoring Licensing" },
          { name: "Software Database Licensing" },
          { name: "Software Programming Licensing" },
          { name: "Software Business Intelligence" },

        ],
        vendor: [
          { name: "MICROSOFT" },
          { name: "CISCO" },
          { name: "ORACLE" },
          { name: "DevExpress" },
          { name: "TEAMVIEWER" },
          { name: "NODEFLUX" },
          { name: "IBM" },
          { name: "REDHAT" },
          { name: "AnyDesk" },
          { name: "Carbonite" },
          { name: "NAVICAT" },
          { name: "PAESSLER" },
          { name: "Progress" },
          { name: "MySQL" },
          { name: "WISS" },
          { name: "Acunetix" },
          { name: "Binary Ninja" },
          { name: "HUAWEI" },
          { name: "Idemas" },
          { name: "Nvivo" },
          { name: "REALVNC" },
          { name: "Retrospect" },
          { name: "Software AG" }
        ]
      },
      { 
        name: "Software Office & Business",
        tags: ["Software Office & Business Licensing", "Client Software Office Application Fpp"],
        list: [
          { name: "Software Cad / Cam Licensing", tag: "Software Office & Business Licensing" },
          { name: "Software Gis / Mapping Licensing", tag: "Software Office & Business Licensing" },
          { name: "Software Office & Business Retail", tag: "Software Office & Business Licensing" },
          { name: "Client Software File Compression Fpp", tag: "Client Software Office Application Fpp" }
        ],
        vendor: [
          { name: "AUTODESK" },
          { name: "BricsCAD" },
          { name: "MICROSOFT" },
          { name: "GSTARCAD" },
          { name: "ZWCAD" },
          { name: "SOLIBRI" },
          { name: "Archicad" },
          { name: "ENSCAPE" },
          { name: "NOVO" },
          { name: "ARTLANTIS" },
          { name: "Agisoft" },
          { name: "Arcport" },
          { name: "GeoStudio" },
          { name: "Midas" },
          { name: "TEAMVIEWER" },
          { name: "V-RAY" },
          { name: "VRAY" },
          { name: "WINZIP" },
        ]
      }
    ]
  }
]

export default catalogue