import React, { useEffect, useRef, useState } from "react"
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap"
import Slider from "react-slick"
import { AnimationOnScroll } from 'react-animation-on-scroll'
import ScrollToTopOnMount from "../helpers/ScrollToTopOnMount"
import { useNavigate } from "react-router-dom"

const images = [
  "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
  "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
  "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
  "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
  "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
  "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
  "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
  "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
  "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
  "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
  "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
]

const business = [
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  },
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  },
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  },
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  },
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  },
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  },
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  },
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  }
]

const settingsMainSlider = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false
};

const settingsSubmainSlider = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1
};


const ProductDetail = () => {
  let sliderMainRef = useRef(null)
  const navigate = useNavigate()
  const [activeImage, setActiveImage] = useState(1)

  useEffect(() => {
    sliderMainRef.slickGoTo(activeImage)
  }, [activeImage])

  return(
    <Container className="mt-5 mb-5">
      <ScrollToTopOnMount/>
      <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={.8} animatePreScroll={true} offset={0}>
        <div className="d-flex gap-2 breadcrumb">
          <a href="/catalogue">
            <div>Catalogue</div>
          </a>
          <div>/</div>
          <div>ABC 001</div>
        </div>
      </AnimationOnScroll>
      <Row className="mt-4">
        <Col xs={12} md={5}>
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={.8} animatePreScroll={true} offset={0}>
            <Slider
              ref={slider => {
                sliderMainRef = slider;
              }}
              { ...settingsMainSlider }
              afterChange={(index) => {
                setActiveImage(index)
              }}
            >
              {
                images.map((item, index) => {
                  return(
                    <div key={`image-${index}`}>
                      <img src={"https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg"} style={{width:"100%", borderRadius:8}}/>
                    </div>
                  )
                })
              }
              
            </Slider>
            <div className="slick-submain">
              <Slider {...settingsSubmainSlider}>
                {
                  images.map((item, index) => {
                    return(
                      <div key={`imagesub-${index}`} className={`pointer ${index+1 === activeImage?"active":""}`} onClick={()=>setActiveImage(index+1)}>
                        <img src={"https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg"} style={{width:"100%", borderRadius: 4}}/>
                      </div>
                    )
                  })
                }
                
              </Slider>
            </div>
          </AnimationOnScroll>
        </Col>
        <Col xs={12} md={7}>
          <AnimationOnScroll animateOnce={true} animateIn="animate__slideInRight" duration={.8} animatePreScroll={true} offset={0}>
            <div className="header">ABC 001</div>
            <div className='mt-3 mb-2'><strong className='text-brand'>Description</strong></div>
            <p>Quisque convallis quam consequat himenaeos lorem. Dolor pretium turpis dapibus ridiculus sollicitudin augue vestibulum taciti orci dignissim. Massa nam lobortis dolor fusce vivamus viverra id odio. Feugiat volutpat pulvinar fermentum sit elementum platea.</p>
            
            <div className='mt-3 mb-2'><strong className='text-brand'>Spesification</strong></div>
            <div>
              <div className="d-flex flex-row gap-2">
                <div style={{width:200}}>
                  <strong>Nama Produk</strong>
                </div>
                <p>ABC 001</p>
              </div>
              <div className="d-flex flex-row gap-2">
                <div style={{width:200}}>
                  <strong>Merk</strong>
                </div>
                <p>ABC</p>
              </div>
              <div className="d-flex flex-row gap-2">
                <div style={{width:200}}>
                  <strong>Product Provider No.</strong>
                </div>
                <p>JMX0100</p>
              </div>
              <div className="d-flex flex-row gap-2">
                <div style={{width:200}}>
                  <strong>Type</strong>
                </div>
                <p>JMX</p>
              </div>
            </div>

            <div className='mt-5 mb-2'><strong className='text-brand'>Get in Touch</strong></div>
            <Button variant='brand-secondary' onClick={()=>navigate("/contact-us")}>Contact Us</Button>
          </AnimationOnScroll>
        </Col>
      </Row>

      <div className='mt-5 mb-2'><strong className='text-brand'>More Product</strong></div>
      <Row>
        {
          business.map((item, index) => (
            <Col key={'our-solution-'+index} xs={6} md={4} lg={3} className='mb-4 hover-zoom pointer'>
              <Card style={{height: '100%'}}>
                <Card.Img variant="top" src={item.image} alt={item.title} />
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text>{item.short_desc}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))
        }
      </Row>
    </Container>
  )
}

export default ProductDetail