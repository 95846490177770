import React, { useEffect, useState } from "react"
import { AnimationOnScroll } from "react-animation-on-scroll"
import { Button, Card, Col, Row } from "react-bootstrap"
import ScrollToTopOnMount from "../helpers/ScrollToTopOnMount"
import catalogue from "../data/catalogue"
import { RiMailSendFill } from "react-icons/ri";

const Catalogue = () => {
  const [tabActive, setTabActive] = useState({
    catalogue: "Hardware",
    category: "Server"
  })
  const [data, setData] = useState({})
  const [dataTemp, setDataTemp] = useState({})
  const [subcategoryActive, setSubcategoryActive] = useState(null)
  const [tagVendor, setTagVendor] = useState(null)

  const loadData = () => {
    const catalogueSelected = catalogue.find(x => x.name === tabActive.catalogue)
    const categorySelected = catalogueSelected.category.find(x => x.name  === tabActive.category)
    setData(categorySelected)
    setDataTemp(categorySelected)
    setSubcategoryActive(null)
    setTagVendor(null)
  }

  useEffect(() => {
    loadData()
  }, [tabActive])

  useEffect(() => {
    if(!subcategoryActive){
      loadData()
    }else{
      const dataFilter = dataTemp.list.filter(x => x.tag === subcategoryActive)
      setData(state => ({...state, list: dataFilter}))

      if(dataTemp.tags_vendor){
        const tag_vendor = dataTemp.tags_vendor.find(x => x.name === subcategoryActive)
        if(tag_vendor){
          setTagVendor(tag_vendor.list)
        }else{
          setTagVendor(null)  
        }
      }else{
        setTagVendor(null)
      }
    }
  }, [subcategoryActive])

  return(
    <div className="p-5">
      <ScrollToTopOnMount/>
      <div>
        <Row>
          <Col md="4" className='mb-3'>
            <AnimationOnScroll animateOnce={true} animateIn="animate__slideInLeft" duration={.5} animatePreScroll={true} offset={0}>
              <div className="box catalogue">
                {
                  catalogue.map((item, index) => {
                    return(
                      <div key={"catalogue-"+index} className="mb-3">
                        <strong className='text-brand-secondary title'>{item.name}</strong>
                        <div className="mt-1 ms-3">
                          {
                            item.category.map((category, idx) => {
                              return(
                                <div key={"category-"+idx} className={`pointer mb-2 subcategory ${tabActive.catalogue === item.name && tabActive.category === category.name? "active" : ""}`} onClick={()=>setTabActive({ catalogue: item.name, category: category.name })}>
                                  <div>{category.name}</div>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </AnimationOnScroll>
          </Col>
          <Col md="8" className='mb-3'>
            <h5>{tabActive.catalogue} - {tabActive.category}</h5>
            {
              data.tags &&
              <div className="mt-3">
                <span>Subcategory: </span>
                <div className="d-flex flex-row gap-2 flex-wrap mt-2">
                  <Button variant={!subcategoryActive ? "brand" : "outline-brand"} onClick={()=>setSubcategoryActive(null)}>All</Button>
                  {
                    data.tags.map((item, index) => (
                      <Button key={"tag"+index} variant={subcategoryActive === item ? "brand" : "outline-brand"} onClick={()=>setSubcategoryActive(item)}>{item}</Button>
                    ))
                  }
                </div>
              </div>
            }
            {
              data.list &&
              <div className="mt-4">
                <span>Catalogue: </span>
                <Row className="mt-2">
                  {
                    data.list.map((item, index) => {
                      return(
                        <Col key={"catalogue-list"+index} xs={6} md={4} lg={4} className='mb-2 hover-zoom'>
                          <Card style={{height: '100%'}}>
                            <Card.Body>
                              {
                                item.tag && <div className="text-brand" style={{fontSize:12}}>{item.tag}</div>
                              }
                              <h5>{item.name}</h5>
                            </Card.Body>
                          </Card>
                        </Col>
                      )
                    })
                  }
                </Row>
              </div>
            }

            {
              data.vendor &&
              <div className="mt-4">
                <span>Vendor: </span>
                <br/>
                <strong>{ data.vendor.map(x => x.name).join(", ") }</strong>
              </div>
            }

            {
              tagVendor &&
              <div className="mt-4">
                <span>Vendor: </span>
                <br/>
                <strong>{ tagVendor.map(x => x.name).join(", ") }</strong>
              </div>
            }
            <hr className="mt-4"/>
            <div className="mt-4 d-flex flex-row gap-4">
              <h5 className="flex-fill">Don't miss out on this opportunity to unlock the full potential of your technology.  Contact us today!</h5>
              <a className='relative pointer' href="/contact-us" >
                <Button variant="brand-secondary" style={{width:180}}>SEND INQUIRY <RiMailSendFill size={18} style={{marginTop:-4}} /></Button>
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Catalogue