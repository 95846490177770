import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { FaRegCheckSquare } from "react-icons/fa"
import whyus from './../assets/why-us.png'
import bgwork from './../assets/bg-work.png'

const About = () => {
  return(
    <div className="about wrap-hidden">
      <Container className='mt-5 pt-5 pb-5'>
        <Row>
          <Col xs={12} md={6}>
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={.8} animatePreScroll={true} offset={0}>
              <div className='header mt-2 text-center-mobile'>About Us</div>
              <p className='mt-4 text-justify text-center-mobile'>We're passionate about leveraging technology to empower businesses and drive innovation. With a commitment to excellence and a customer-centric approach, we've established ourselves as a trusted leader in the IT industry, delivering comprehensive solutions and exceptional service to businesses of all sizes across various industries.</p>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__slideInDown" duration={.8} animatePreScroll={true} offset={0}>
              <div className='text-center-mobile mt-4'><strong className='text-brand'>Why Choose Us?</strong></div>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__slideInUp" duration={.8} animatePreScroll={true} offset={0}>
              <div className='mt-3 d-flex flex-row gap-3 order-icon'>
                <FaRegCheckSquare size={70} style={{marginTop:-20}}/>
                <p><strong>Expertise:</strong> With years of experience in the IT industry, our team of experts possesses the knowledge, skills, and experience to deliver cutting-edge solutions tailored to meet the unique needs of each client.</p>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__slideInUp" duration={.8} animatePreScroll={true} offset={0}>
              <div className='mt-1 d-flex flex-row gap-3 order-icon'>
                <FaRegCheckSquare size={70} style={{marginTop:-20}}/>
                <p><strong>Customer Focus:</strong> Our clients are at the heart of everything we do. We prioritize customer satisfaction above all else, striving to exceed expectations with personalized service, ongoing support, and a dedication to your success.</p>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce={true} animateIn="animate__slideInUp" duration={.8} animatePreScroll={true} offset={0}>
              <div className='mt-1 d-flex flex-row gap-3 order-icon'>
                <FaRegCheckSquare size={70} style={{marginTop:-20}}/>
                <p><strong>Innovation:</strong> We're committed to staying at the forefront of technological innovation, constantly exploring new technologies, methodologies, and best practices to ensure our clients are equipped with the latest tools and solutions to drive success.</p>
              </div>
            </AnimationOnScroll>
          </Col>
          <Col xs={12} md={6}>
            <AnimationOnScroll animateOnce={true} animateIn="animate__slideInRight" duration={.8} animatePreScroll={true} offset={0}>
              <img src={whyus} style={{ width: "100%" }} alt=""/>
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>
      <div className='mt-2 pt-5 pb-5'>
        <Container>
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={.8} animatePreScroll={true}>
            <div className="text-center-mobile header mt-2 text-center">Our Mission</div>
            <div className="text-center-mobile desc text-center mt-2">Our mission is simple: to help businesses thrive in the digital age by providing innovative IT solutions and unparalleled support. We believe that technology should be a catalyst for growth, enabling businesses to overcome challenges, seize opportunities, and achieve their full potential.</div>
          </AnimationOnScroll>
        </Container>
      </div>
      <div className='bg-pink'
        style={{
          backgroundImage: `url(${bgwork})`,
          backgroundSize: '80%'
        }}
      >
        <Container className='pt-5 pb-5'>
          <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" duration={.8} animatePreScroll={true} offset={100}>
            <div className='header text-center'>How ESS Works</div>
            <Row className='mt-5'>
              <Col xs={12} md={7} lg={7} className='mb-4 text-center'>
                <div className='work-card white'>
                  <div className='title mt-3'>Our Services</div>
                  <p className='mt-1'>From Hardware, Software, Managed IT services to cloud computing and consulting, we offer a comprehensive range of services designed to meet the diverse needs of businesses in today's fast-paced digital world. Whether you're a small startup looking to establish your IT infrastructure or a large enterprise seeking to optimize your operations, we have the expertise and resources to help you succeed.</p>
                </div>
              </Col>
              <Col xs={12} md={5} lg={5} className='mb-4 text-center'>
                <div className='work-card white'>
                  <div className='title mt-3'>Our Team</div>
                  <p className='mt-1'>Our success is built on the talent and dedication of our team members. From our skilled technicians and engineers to our knowledgeable consultants and support staff, we're proud to have assembled a team of industry professionals who share a passion for technology and a commitment to delivering exceptional results for our clients.</p>
                </div>
              </Col>
            </Row>
          </AnimationOnScroll>
        </Container>
      </div>
    </div>
  )
}

export default About