import React from "react"
import { AnimationOnScroll } from "react-animation-on-scroll"
import { Button, Card, Col, Container, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import ScrollToTopOnMount from "../helpers/ScrollToTopOnMount"

const business = [
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  },
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  },
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  },
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  },
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  },
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  },
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  },
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  }
]

const Software = () => {
  const navigate = useNavigate()

  return(
    <div>
      <ScrollToTopOnMount/>
      <div className='bg-header relative'>
        <div className='box-dot'/>
        <Container className='fade-in-text pt-5'>
          <div className='text-center-mobile text-center'><strong className='text-brand'>SOFTWARE</strong></div>
          <div className='pb-4 text-center header'>Empower Your Business with Powerful Software Solutions</div>
          <div className='pb-5 header-desc'>Discover our comprehensive catalog of software for business. Through our deep partnerships with trusted brands, you’ll find the right software solutions for your needs.</div>
        </Container>
      </div>
      <Container className="pt-5 mb-5">
        <AnimationOnScroll animateOnce={true} animateIn="animate__slideInUp" duration={.8} animatePreScroll={true} offset={0}>
          <Row>
            {
              business.map((item, index) => (
                <Col key={'our-solution-'+index} xs={6} md={4} lg={3} className='mb-4 hover-zoom pointer' onClick={() => navigate("/detail/software/abc-001")}>
                  <Card style={{height: '100%'}}>
                    <Card.Img variant="top" src={item.image} alt={item.title} />
                    <Card.Body>
                      <Card.Title>{item.title}</Card.Title>
                      <Card.Text>{item.short_desc}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            }
          </Row>
        </AnimationOnScroll>
      </Container>
    </div>
  )
}

export default Software