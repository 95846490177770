import React, { useEffect, useRef, useState } from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import { FaChevronRight } from "react-icons/fa"
import { useNavigate } from "react-router-dom"

const business = [
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  },
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  },
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  },
  {
    image: "https://mitrahukum.org/wp-content/uploads/2012/09/dummy-post-square-1-768x768.jpg",
    title: "Lorem Ipsum",
    short_desc: "Ultrices cras egestas tincidunt lorem risus turpis nunc curabitur tellus pulvinar porttitor pede ut donec"
  }
]

const header_height = 200

const ProductPreview = () => {
  const navigate = useNavigate()
  const hardware = useRef(null)
  const software = useRef(null)
  const solution = useRef(null)
  const tabWrapper = useRef(null)
  const [hardwarePosition, setHardwarePosition] = useState(null)
  const [softwarePosition, setSoftwarePosition] = useState(null)
  const [solutionPosition, setSolutionPosition] = useState(null)
  const [scrollY, setScrollY] = useState(null)
  const [isFixed, setIsFixed] = useState(false)
  const [activeTab, setActiveTab] = useState('hardware')
  const [tabWrapperEl, setTabWrapperEl] = useState(null)

  const onScroll = () => {
    setScrollY(window.scrollY + header_height)
  }
  
  useEffect(() => {
    setHardwarePosition(hardware?.current?.getBoundingClientRect())
    setSoftwarePosition(software?.current?.getBoundingClientRect())
    setSolutionPosition(solution?.current?.getBoundingClientRect())
    setTabWrapperEl(tabWrapper?.current?.getBoundingClientRect())
  }, [])

  useEffect(() => {
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    if(hardwarePosition && softwarePosition && solutionPosition && scrollY){
      if(scrollY < hardwarePosition.top){
        setIsFixed(false)
      }else if(scrollY >= hardwarePosition.top && scrollY < softwarePosition.top){
        setActiveTab('hardware')
        setIsFixed(true)
      }else if(scrollY >= softwarePosition.top && scrollY < solutionPosition.top){
        setActiveTab('software')
      }else if(scrollY >= solutionPosition.top && scrollY < (solutionPosition.height + solutionPosition.top)){
        setActiveTab('solution')
        setIsFixed(true)
      }else if(scrollY >= (solutionPosition.height + solutionPosition.top)){
        setIsFixed(false)
      }
    }
  }, [hardwarePosition, softwarePosition, solutionPosition, scrollY])

  return(
    <div>
      <Row>
        <Col md={3} ref={tabWrapper}>
          <div className={`tab-product ${isFixed?'fixed':''} d-grid gap-2`} style={{width: tabWrapperEl ? tabWrapperEl.width - 20 : 0}}>
            <Button variant={activeTab==='hardware'?'brand-secondary':'light'} className="mt-2" onClick={()=>window.scrollTo(0, hardwarePosition.top - 120)}>Hardware</Button>
            <Button variant={activeTab==='software'?'brand-secondary':'light'} className="mt-2" onClick={()=>window.scrollTo(0, softwarePosition.top - 120)}>Software</Button>
            <Button variant={activeTab==='solution'?'brand-secondary':'light'} className="mt-2" onClick={()=>window.scrollTo(0, solutionPosition.top - 120)}>Solution</Button>
          </div>
        </Col>
        <Col md={9}>
          <div ref={hardware} className="use-case mb-5">
            <strong className='text-brand-secondary header'>Hardware</strong>
            <div className="title">Empower Your Business with High-Performance IT Hardware</div>
            <p className="mt-2">Explore IT hardware and technology for your business, including servers, storage, networking, laptops, monitors, printers and more.</p>
            <div className="mt-3">
              <strong>Why Choose Us for Your Business Hardware?</strong>
              <ul className="mt-2">
                <li><strong>Expert Guidance:</strong> Our IT specialists will analyze your needs and recommend the optimal hardware solutions for your business.</li>
                <li><strong>Wide Selection:</strong> Explore a diverse range of hardware, from high-performance servers to secure laptops and powerful workstations.</li>
                <li><strong>Scalability & Future-Proofing:</strong> Invest in hardware that can grow and adapt alongside your business demands.</li>
                <li><strong>Competitive Pricing:</strong> Gain access to competitive pricing and flexible plans to suit your budget.</li>
              </ul>

              <div className="box mt-2">
                <Row>
                  <Col md={12} lg={12}>
                    <div className="me-4 mb-2"><strong>Key Hardware Categories</strong></div>
                  </Col>
                  <Col xs={12} md={12} lg={12} className="mt-2">
                    <Row>
                      <Col xs={12} md={6} lg={6} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">1</div>
                          </Col>
                          <Col>
                            <b>Servers & Storage</b>
                            <p>High-performance servers (rack, tower, blade) and secure storage solutions (internal/external HDDs, NAS) for seamless data management</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} lg={6} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">2</div>
                          </Col>
                          <Col>
                            <b>Networking</b>
                            <p>Robust networking infrastructure with reliable hubs, switches, routers, and firewalls to ensure efficient data flow.</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} lg={6} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">3</div>
                          </Col>
                          <Col>
                            <b>Workstations & Desktops</b>
                            <p>Customizable workstations and desktops designed for optimal performance and productivity. Offer configurations for various needs (graphic design, engineering, data analysis).</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} lg={6} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">4</div>
                          </Col>
                          <Col>
                            <b>Laptops & Mobile Devices</b>
                            <p>Secure and feature-rich laptops, tablets, and mobile devices for enhanced employee mobility and remote work capabilities.</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} lg={6} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">5</div>
                          </Col>
                          <Col>
                            <b>Security Solutions</b>
                            <p>Implement robust security measures with firewalls, antivirus software, and access control hardware for comprehensive data protection.</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Button variant="brand-secondary" className="mt-3 ms-5" onClick={() => navigate("/contact-us")}>Need Customization?</Button>
              </div>
            </div>
          </div>
          <hr/>
          <div ref={software} className="use-case mb-5">
            <strong className='text-brand-secondary header'>Software</strong>
            <div className="title">Empower Your Business with Powerful Software Solutions</div>
            <p className="mt-2">Discover our comprehensive catalog of software for business. Through our deep partnerships with trusted brands, you’ll find the right software solutions for your needs.</p>
            <div className="mt-3">
              <strong>Why Choose Us For Your Software Solution?</strong>
              <ul className="mt-2">
                <li><strong>Expert Recommendations:</strong> Our team of IT specialists are highly knowledgeable and dedicated to understanding your unique business needs. We'll recommend the most suitable software solutions to optimize your workflow and achieve your goals.</li>
                <li><strong>Seamless Integration:</strong> We ensure smooth integration with your existing infrastructure and workflows, minimizing disruption and ensuring a smooth transition to new software.</li>
                <li><strong>Ongoing Support:</strong> Benefit from dedicated support teams offering comprehensive training, troubleshooting assistance, and ongoing maintenance to guarantee a successful software experience.</li>
                <li><strong>Scalable Solutions:</strong> We offer flexible licensing options that adapt to your growing business needs. Your software investment will scale alongside your success, ensuring you always have the tools you need.</li>
                <li><strong>Proven Track Record:</strong> We have a history of helping businesses like yours achieve significant results through effective software implementation. Explore our client success stories to see the impact we can make.</li>
              </ul>

              <div className="box mt-2">
                <Row>
                  <Col md={12} lg={12}>
                    <div className="me-4 mb-2"><strong>Software Categories We Offer</strong></div>
                  </Col>
                  <Col xs={12} md={12} lg={12} className="mt-2">
                    <Row>
                      <Col xs={12} md={6} lg={6} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">1</div>
                          </Col>
                          <Col>
                            <b>Business applications</b>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} lg={6} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">2</div>
                          </Col>
                          <Col>
                            <b>Cloud field services</b>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} lg={6} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">3</div>
                          </Col>
                          <Col>
                            <b>Communication & Collaboration</b>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} lg={6} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">4</div>
                          </Col>
                          <Col>
                            <b>Graphics & design</b>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} lg={6} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">5</div>
                          </Col>
                          <Col>
                            <b>Business Intelligence & Data Analytics</b>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} lg={6} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">6</div>
                          </Col>
                          <Col>
                            <b>Networking & Servers</b>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} lg={6} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">7</div>
                          </Col>
                          <Col>
                            <b>Operating systems</b>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} lg={6} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">8</div>
                          </Col>
                          <Col>
                            <b>Security & Compliance</b>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} lg={6} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">9</div>
                          </Col>
                          <Col>
                            <b>System service & support</b>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={6} lg={6} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">10</div>
                          </Col>
                          <Col>
                            <b>Utilities & Virtualization</b>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Button variant="brand-secondary" className="mt-3 ms-5" onClick={() => navigate("/contact-us")}>Need Customization?</Button>
              </div>
            </div>
          </div>
          <hr/>
          <div ref={solution} className="use-case mb-5">
            <strong className='text-brand-secondary header'>Solution</strong>
            <div className="title">Your Trusted Partner for Comprehensive IT Solutions</div>
            <p className="mt-2">With us, you get access to deep expertise, broad capabilities and unmatched scale as a leading Solutions Integrator.</p>
            <div className="mt-3">
              <strong>Why Choose Us for IT Solutions?</strong>
              <ul className="mt-2">
                <li><strong>Partnership Approach:</strong> We collaborate closely with you to understand your unique business goals and IT challenges.</li>
                <li><strong>Custom-Tailored Solutions:</strong> We don't offer one-size-fits-all solutions. We design and implement services that fit your specific needs.</li>
                <li><strong>Experienced Team:</strong> Our team of certified IT professionals possesses extensive knowledge and experience to optimize your IT infrastructure.</li>
                <li><strong>Seamless Integration:</strong> Benefit from a unified IT ecosystem where hardware, software, and services work flawlessly together, eliminating compatibility issues and ensuring smooth operation.</li>
                <li><strong>Scalability & Future-Proofing:</strong> We help you build a scalable and adaptable IT foundation to support your business growth.</li>
              </ul>

              <div className="box mt-2">
                <Row>
                  <Col md={12} lg={12}>
                    <div className="me-4 mb-2"><strong>Our Key IT Solution Areas</strong></div>
                  </Col>
                  <Col xs={12} md={12} lg={12} className="mt-2">
                    <Row>
                      <Col xs={12} md={12} lg={12} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">1</div>
                          </Col>
                          <Col>
                            <b>Consulting Services</b>
                            <p>Navigate the complexities of your IT ecosystem with confidence. Our technical experts and technology specialists are equipped with partner certifications, industry knowledge and deep expertise to guide you along the way.</p>
                            <div className="mt-2">
                              <b style={{fontSize:15}}>Get guidance for:</b>
                              <ul className="mt-2">
                                <li>Data center transformation</li>
                                <li>Cloud and workload alignment</li>
                                <li>Security and disaster recovery</li>
                                <li>IT optimization</li>
                                <li>Automation and orchestration</li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={12} lg={12} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">2</div>
                          </Col>
                          <Col>
                            <b>Managed IT Services</b>
                            <p>Offload your IT burden to our trusted team so you can focus on the road ahead. Partnering with Insight for managed services can increase service levels and IT efficiency, while simultaneously reducing expenses.</p>
                            <div className="mt-2">
                              <b style={{fontSize:15}}>Our offerings include:</b>
                              <ul className="mt-2">
                                <li>As a service</li>
                                <li>Managed storage, backup and recovery</li>
                                <li>Managed cloud, network and compute</li>
                                <li>Managed security</li>
                                <li>Managed support</li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={12} lg={12} className="use-case mb-2">
                        <Row>
                          <Col xs="auto" style={{paddingRight:0}}>
                            <div className="key-number">3</div>
                          </Col>
                          <Col>
                            <b>IT infrastructure services</b>
                            <p>Optimize your entire IT ecosystem to accelerate transformation, simplify operations and meet growing workload demands. We deliver expert support to help you build responsive and integrated systems.</p>
                            <div className="mt-2">
                              <b style={{fontSize:15}}>Our services will help improve your:</b>
                              <ul className="mt-2">
                                <li>Data center</li>
                                <li>Network</li>
                                <li>Deployments</li>
                                <li>IT skills gaps</li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Button variant="brand-secondary" className="mt-3 ms-5" onClick={() => navigate("/contact-us")}>Need Customization?</Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ProductPreview