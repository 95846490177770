import React, { useEffect, useRef, useState } from "react"
import './../scss/slider.scss'
import $ from 'jquery'
import { Button } from "react-bootstrap"

import hardware from '../assets/hardware2.jpg'
import software from '../assets/software2.jpg'
import solution from '../assets/solution2.jpg'

let slides = document.getElementsByClassName('slide')

const Banner = () => {
  const intervalRef = useRef()
  const [index, setIndex] = useState(0)
  const showSlide = () => {
    if ($('body').hasClass('is-sliding')){
      return;
    }

    let idxs = 0
    for (const slide of slides) {
      $(slide).toggleClass('is-active', (idxs === index)); 
      $(slide).toggleClass('is-prev', (idxs === index - 1)); 
      $(slide).toggleClass('is-next', (idxs === index + 1)); 
      
      // Add and remove is-sliding class
      $('body').addClass('is-sliding');

      setTimeout(function(){
        $('body').removeClass('is-sliding');
      }, 1000);

      idxs++
    }
  }
  
  const prevSlide = () => {
    // If on first slide, loop to last
    if (index <= 0) {
      setIndex(slides.length - 1)
    }else{
      setIndex(index - 1)
    }

  }

  const nextSlide = () => {
    // If on last slide, loop to firste
    const idx = index + 1
    if (idx >= slides.length) {
      setIndex(0)
    }else{
      setIndex(idx)
    }
  }

  const intervalTime = () => {
    intervalRef.current = setInterval(() => {
      setIndex(state => {
        const idx = state + 1
        if (idx >= slides.length) {
          return 0
        }else{
          return idx
        }
      })
    }, 7000);
  }

  useEffect(() => {
    showSlide()
    clearInterval(intervalRef.current)
    intervalTime()
  }, [index])

  // useEffect(() => {
  //   //Clearing the interval
  //   intervalTime()
  //   return () => clearInterval(intervalRef.current);
  // }, [])

  return(
    <section className="slides"> 
      
      <section className="slides-nav">
        <nav className="slides-nav__nav">
          <button className="slides-nav__prev js-prev" onClick={prevSlide}>PREV</button>
          <button className="slides-nav__next js-next" onClick={nextSlide}>NEXT</button>
        </nav>
      </section>

      <section className="slide is-active">
        <div className="slide__content">
          <figure className="slide__figure">
            <div className="slide__img" style={{backgroundImage: `url(${hardware})`}}></div>
          </figure>
          <header className="slide__header">
            <div className="slide__title">
              <span className="title-line"><span><h1 className="text-brand header mt-1 text-white">Product Hardware</h1></span></span>
              <span className="title-line mt-3"><span className="subtitle text-white"><h3>Empower Your Business with High-Performance IT Hardware</h3></span></span>
              <span className="title-line">
                <span>
                  <a className='relative pointer' href="/#" >
                    <Button variant='brand-secondary mt-4'>Read More</Button>
                  </a>
                </span>
              </span>
            </div>
          </header>
        </div>
      </section>

      <section className="slide">
        <div className="slide__content">
          <figure className="slide__figure">
            <div className="slide__img" style={{backgroundImage: `url(${software})`}}></div>
          </figure>
          <header className="slide__header">
            <div className="slide__title">
              <span className="title-line"><span><h1 className="text-brand header mt-1 text-white">Product Software</h1></span></span>
              <span className="title-line mt-3"><span className="subtitle text-white"><h3>Empower Your Business with Powerful Software Solutions</h3></span></span>
              <span className="title-line">
                <span>
                  <a className='relative pointer' href="/#" >
                    <Button variant='brand-secondary mt-4'>Read More</Button>
                  </a>
                </span>
              </span>
            </div>
          </header>
        </div>
      </section>

      <section className="slide">
        <div className="slide__content">
          <figure className="slide__figure">
            <div className="slide__img" style={{backgroundImage: `url(${solution})`}}></div>
          </figure>
          <header className="slide__header">
            <div className="slide__title">
              <span className="title-line"><span><h1 className="text-brand header mt-1 text-white">Solution</h1></span></span>
              <span className="title-line mt-3"><span className="subtitle text-white"><h3>Your Trusted Partner for Comprehensive IT Solutions</h3></span></span>
              <span className="title-line">
                <span>
                  <a className='relative pointer' href="/#" >
                    <Button variant='brand-secondary mt-4'>Read More</Button>
                  </a>
                </span>
              </span>
            </div>
          </header>
        </div>
      </section>

    </section>
  )
}

export default Banner