import React, { useEffect, useRef, useState } from "react"
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap"
import { AnimationOnScroll } from 'react-animation-on-scroll'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import iconLoc from './../assets/ep_location-information.png'
import iconPhone from './../assets/bi_telephone.png'
import iconFax from './../assets/cil_fax.png'

const MySwal = withReactContent(Swal)

const Contact = () => {
  const formRef = useRef()
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    name: '',
    email: '',
    organization: '',
    phone: '',
    message: ''
  })
  const [error, setError] = useState({
    name: '',
    email: '',
    message: '',
    organization: '',
    phone: ''
  })

  const submit = () => {
    setLoading(true)

    var mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if(
      form.name==='' ||
      mailformat.test(form.email) === false ||
      form.message==='' ||
      form.organization==='' ||
      form.phone===''
    ){
      if(form.name===''){
        setError(state => ({...state, name: 'Required'}))
      }else{
        setError(state => ({...state, name: ''}))
      }

      if(mailformat.test(form.email) === false){
        setError(state => ({...state, email: 'Invalid email address'}))
      }else{
        setError(state => ({...state, email: ''}))
      }

      if(form.message===''){
        setError(state => ({...state, message: 'Required'}))
      }else{
        setError(state => ({...state, message: ''}))
      }

      if(form.organization===''){
        setError(state => ({...state, organization: 'Required'}))
      }else{
        setError(state => ({...state, organization: ''}))
      }

      if(form.phone===''){
        setError(state => ({...state, phone: 'Required'}))
      }else{
        setError(state => ({...state, phone: ''}))
      }

      setLoading(false)
      return false
    }

    setError({
      name: '',
      email: '',
      message: '',
      organization: '',
      phone: ''
    })

  }

  useEffect(() => {
    window.scrollTo({
      top: 0
    })
  }, [])

  return(
    <div className="contact">
      <Container className="pt-5 pb-5">
        <Row>
          <Col xs={12} md={6} className="pe-5 mb-5">
            <AnimationOnScroll animateOnce={true} animateIn="animate__slideInDown" duration={.8} animatePreScroll={true}>
              <div className="help-header">
                <div className="relative">
                  <div className="box-brand-secondary" />
                  <div className="header text-white">Let’s do big things together.</div>
                </div>
              </div>
              <p className="mt-5 mb-5">
              Ready to take your business to the next level with innovative IT solutions from Us? Contact us today to learn more about our services, schedule a consultation, or discuss how we can tailor a solution to meet your unique business needs.
              </p>
              <div className="d-flex flex-row align-items-center">
                <img src={iconLoc} width="18px" alt="" />
                <p className="ms-3">
                  Centennial Tower, 38’th floor, Unit H
                  <br/>Jl. Gatot Subroto, kavling 24-25, Jakarta Selatan, 12930, Indonesia
                </p>
              </div>
              <div className="d-flex flex-row align-items-center mt-3">
                <img src={iconPhone} width="18px" alt="" />
                <p className="ms-3">
                  021 22958146
                </p>
              </div>
              <div className="d-flex flex-row align-items-center mt-3">
                <img src={iconPhone} width="18px" alt="" />
                <p className="ms-3">
                  081119077005
                </p>
              </div>
              <div className="d-flex flex-row align-items-center mt-3">
                <img src={iconFax} width="18px" alt="" />
                <p className="ms-3">
                  sales@exel-int.com
                </p>
              </div>
            </AnimationOnScroll>
          </Col>
          <Col xs={12} md={6} className="ps-4">
            <AnimationOnScroll animateOnce={true} animateIn="animate__slideInUp" duration={.8} animatePreScroll={true}>
              <div className='text-center-mobile'><strong className='text-brand'>CONTACT US</strong></div>
              <div className="text-center-mobile header mt-2">Let's Connect</div>
              <p className="desc mt-4 text-center-mobile">Entrust us with the opportunity to address your challenges by providing customized solutions crafted specifically for your needs.</p>

              <Form className="mt-4" ref={formRef}>
                <Form.Group className="mb-3">
                  <Form.Label><strong>Full Name</strong> <span className="error">{error.name}</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type your name"
                    value={form.name}
                    onChange={(event) => setForm(state => ({...state, name: event.target.value}))}
                    disabled={loading}
                    name="name"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label><strong>Mobile Phone</strong> <span className="error">{error.phone}</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type your mobile phone"
                    value={form.phone}
                    onChange={(event) => setForm(state => ({...state, phone: event.target.value}))}
                    disabled={loading}
                    name="phone"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label><strong>Business Email</strong> <span className="error">{error.email}</span></Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Type your email"
                    value={form.email}
                    onChange={(event) => setForm(state => ({...state, email: event.target.value}))}
                    disabled={loading}
                    name="email"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label><strong>Company/Organization</strong> <span className="error">{error.organization}</span></Form.Label>
                  <Form.Control
                    placeholder="Type your company/organization"
                    value={form.organization}
                    onChange={(event) => setForm(state => ({...state, organization: event.target.value}))}
                    disabled={loading}
                    name="organization"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label><strong>To better assist you, please describe how we can help</strong> <span className="error">{error.message}</span></Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={6} placeholder="Type your message"
                    value={form.message}
                    onChange={(event) => setForm(state => ({...state, message: event.target.value}))}
                    disabled={loading}
                    name="message"
                  />
                </Form.Group>
                <div className="d-flex flex-row gap-3 mb-3">
                  <Form.Check
                    type={'checkbox'}
                  />
                  <p className="text-justify">By checking this box, I consent to receive Insight marketing emails. We respect your privacy and will not share your personal information with any other company, person or identity.</p>
                </div>
                <Form.Group className="mb-3">
                  <Button variant="brand-secondary" disabled={loading} style={{width:'100%'}} onClick={()=>submit()}>
                    {
                      loading?(
                        <>
                          <Spinner animation="border" size="sm" className="me-2"/> Loading
                        </>
                      ):(
                        <>Submit</>
                      )
                    }
                  </Button>
                </Form.Group>
              </Form>
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Contact